<template>
  <div>
    <ProDocumentBreadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <ProReport
      ref="proReport"
      title="Document.SearchFileUploadComponentList"
      :columns="columns"
      v-model="fileUploadList"
      row-key="id"
      auto-search
      show-columns-setting
      :loading="loading"
    />
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs.vue";
import ProReport from "@/components/PROSmart/Report/ProReport";
export default {
  name: "FileUpload",
  components: { ProDocumentBreadcrumbs, ProReport },
  data() {
    return {
      fileUploadList: [],
      loading: false,
      emptyContent: "*",
      fileTypeChipStr: [],
    };
  },

  mounted() {
    this.getFileUploadList();
  },

  methods: {
    getFileUploadList() {
      this.loading = true;
      this.$proSmart.admin
        .GetFileUploadInfo(this)
        .then((res) => {
          this.fileUploadList = res.map((item, index) => {
            this.fileTypeChipStr.push(item.acceptFileTypes.split(","));
            return {
              id: index + 1,
              ...item,
              maxFile: item.maxFile ? item.maxFile : 0,
              minFile: item.minFile ? item.minFile : 0,
              descriptionHided: item.descriptionHided === "True" ? true : false,
              descriptionRequired:
                item.descriptionRequired === "True" ? true : false,
              acceptFileTypes: item.acceptFileTypes
                ? item.acceptFileTypes.split(",")
                : [""],
            };
          });
          this.fileTypeChipStr = this.getChipList(this.fileTypeChipStr.flat());
          this.$refs.proReport.nextTickSearch();
        })
        .finally(() => (this.loading = false));
    },
    getChipList(list) {
      const type = {};
      let temp = list.filter((item) => item.split(" ").length === 1);
      temp.forEach((v) => {
        if (!type[v]) type[v] = true;
      });
      return Object.keys(type);
    },
  },

  computed: {
    breadcrumbs() {
      return [{ name: "menu.FileUploadList", icon: "PROSmart-Docs" }];
    },
    columns() {
      return [
        {
          name: "workflowCode",
          label: this.getRes("CustMenu.Field.WorkflowCode"),
          field: "workflowCode",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "workflowName",
          label: this.getRes("CustMenu.Field.WorkflowName"),
          field: "workflowName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "formName",
          label: this.getRes("Form.Field.formName"),
          field: "formName",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
        },
        {
          name: "formParameter",
          label: this.getRes("Form.Option.ParameterName"),
          field: "formParameter",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
          defaultShow: false,
        },
        {
          name: "formLabel",
          label: this.getRes("Form.Field.MessageLabel"),
          field: "formLabel",
          sortable: true,
          align: "left",
          searchable: true,
          type: "i18n",
        },
        {
          name: "componentType",
          label: this.getRes("CustMenu.Field.Type"),
          field: "componentType",
          sortable: true,
          align: "left",
          searchable: true,
          type: "string",
          defaultShow: false,
        },
        {
          name: "maxSize",
          label: this.getRes("Form.Option.MaxTotalSize"),
          field: "maxSize",
          sortable: true,
          align: "left",
          searchable: true,
          type: "fileSize",
        },
        {
          name: "maxFile",
          label: this.getRes("Form.Option.MaxTotalFile"),
          field: "maxFile",
          sortable: true,
          align: "left",
          searchable: true,
          type: "number",
        },
        {
          name: "minFile",
          label: this.getRes("Form.Option.MinTotalFile"),
          field: "minFile",
          sortable: true,
          align: "left",
          searchable: true,
          type: "number",
        },
        {
          name: "descriptionHided",
          label: this.getRes("Form.Col.DescriptionHided"),
          field: "descriptionHided",
          sortable: true,
          align: "left",
          searchable: true,
          type: "boolean",
          list: [
            { label: "Form.Option.True", value: true },
            { label: "Form.Option.False", value: false },
          ],
        },
        {
          name: "descriptionRequired",
          label: this.getRes("Form.Col.DescriptionRequired"),
          field: "descriptionRequired",
          sortable: true,
          align: "left",
          searchable: true,
          type: "boolean",
          list: [
            { label: "Form.Option.True", value: true },
            { label: "Form.Option.False", value: false },
          ],
        },
        {
          name: "acceptFileTypes",
          label: this.getRes("Form.Option.AcceptedFileTypes"),
          field: "acceptFileTypes",
          sortable: true,
          align: "left",
          searchable: true,
          type: "multipleChip",
          nullKey: true,
          emptyContent: this.emptyContent,
          list: this.fileTypeChipStr,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  th,
  td {
    vertical-align: middle;
  }
}
</style>
